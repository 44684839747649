import { Alert, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from '~components/Button/Button';
import FormProvider, { RHFTextField, RHFSelect2 } from '~components/hook-form';
import registerSchema from '../schema/register.schema';
import { useRegisterMutation } from '../services/authApi';
import { useRouter } from 'next/router';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'next/navigation';
import { useSelector } from 'react-redux';
import { getReferralId, sendTaggingRegister, clearReferralId } from '~services/tagging';
import { useCallback, useState } from 'react';
import { useCountryQuery } from '~screens/account/services/accountApi';
type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
  afterSubmit?: string;
};
function AuthRegisterForm({
  onRegisterSuccess
}: any) {
  const {
    push
  } = useRouter();
  const searchParams: any = useSearchParams();
  const id = searchParams.get('id');
  const [register, {
    isLoading
  }] = useRegisterMutation();
  const [referialId, setReferialId] = useState(() => getReferralId());
  const {
    user
  } = useSelector((state: any) => state.auth);
  const order_all_submits = useSelector((state: any) => state.order);
  const {
    step1FormData: {
      _id: orderId1
    },
    step2FormData: {
      _id: orderId2
    },
    step3FormData: {
      orderId: orderId3
    },
    step4FormData: {
      orderId: orderId4
    }
  } = order_all_submits || {};
  const orderId = orderId4 || orderId3 || orderId2 || orderId1 || undefined;
  let {
    step1FormData: {
      recipientName,
      gifterName = "",
      gifterEmail
    }
  } = order_all_submits || {};

  // Split the full name into an array of its parts
  const nameParts = (gifterName?.trim() || "").split(/\s+/);
  let defaultLastName;
  let defaultFirstName;
  if (nameParts.length == 1) {
    defaultFirstName = nameParts[0];
    defaultLastName = '';
  } else {
    defaultLastName = nameParts.pop();
    defaultFirstName = nameParts.join(' ');
  }
  const {
    step4FormData: {
      country
    }
  } = useSelector((state: any) => state.order);
  const {
    data: countryData = []
  }: any = useCountryQuery({
    country
  });
  const methods = useForm<FormValuesProps>({
    resolver: registerSchema,
    defaultValues: {
      country: '647d6359ce36eb9866cdf1c3',
      firstName: defaultFirstName,
      lastName: defaultLastName,
      email: gifterEmail,
      phoneNumber: '+1'
    }
  });
  const {
    handleSubmit,
    formState: {
      errors
    }
  } = methods;
  const handleOnPaste = (e: any) => {
    if (process.env.NODE_ENV === 'production') e?.preventDefault?.();
  };
  const handleCreateAccountCallback = useCallback((data: any) => {
    (async () => {
      if (!recipientName) recipientName = 'Song Recipient';
      let userId = user?.userDto?._id;
      const response = await register({
        ...data,
        roleName: 'Client',
        referralUserId: referialId,
        userId: userId || undefined,
        orderId,
        recipientName
      });
      const {
        error,
        data: respData
      }: any = response || {};
      if (error) toast.error(error?.data?.message);
      if (respData) {
        // toast.success("User registered successfully")

        sendTaggingRegister({
          ...(orderId ? {
            transaction_id: orderId
          } : {})
        });
        clearReferralId();
        if (onRegisterSuccess) onRegisterSuccess();else push('/');
      }
    })();
  }, [orderId]);
  const handleCreateAccount = handleSubmit(handleCreateAccountCallback);
  return <FormProvider methods={methods} data-sentry-element="FormProvider" data-sentry-component="AuthRegisterForm" data-sentry-source-file="AuthRegisterForm.tsx">
      <Stack spacing={2.5} data-sentry-element="Stack" data-sentry-source-file="AuthRegisterForm.tsx">
        {!!errors.afterSubmit && <Alert severity="error">
            {errors.afterSubmit.message}
          </Alert>}
        <Stack direction={{
        xs: 'column',
        sm: 'row'
      }} spacing={2} data-sentry-element="Stack" data-sentry-source-file="AuthRegisterForm.tsx">
          <RHFTextField name="firstName" label="First name" data-sentry-element="RHFTextField" data-sentry-source-file="AuthRegisterForm.tsx" />
          <RHFTextField name="lastName" label="Last name" data-sentry-element="RHFTextField" data-sentry-source-file="AuthRegisterForm.tsx" />
        </Stack>

        <RHFSelect2 options={countryData} getOptionKey={(item: any) => item?._id || null} getOptionLabel={(item: any) => typeof item == 'object' ? item?.name : countryData.find((x: any) => x._id == item)?.name || ""} name="country" label="Country" data-sentry-element="RHFSelect2" data-sentry-source-file="AuthRegisterForm.tsx" />

        <RHFTextField name="email" label="Email address" data-sentry-element="RHFTextField" data-sentry-source-file="AuthRegisterForm.tsx" />
        <RHFTextField name="confirmEmail" label="Confirm email" onPaste={handleOnPaste} data-sentry-element="RHFTextField" data-sentry-source-file="AuthRegisterForm.tsx" />
        <RHFTextField name="password" label="Password" data-sentry-element="RHFTextField" data-sentry-source-file="AuthRegisterForm.tsx" />
        <Button onClick={handleCreateAccount} loading={isLoading} title='Create account' data-sentry-element="Button" data-sentry-source-file="AuthRegisterForm.tsx" />
      </Stack>
    </FormProvider>;
}
export default AuthRegisterForm;